<template>
    <div>
        <div class="page-wrapper">
            <el-pagination layout="total,prev, pager, next" :total="totalItems" :current-page="Tpage.currentPage"
                @current-change="handleCurrentChange" :page-size="Tpage.currentSize">
            </el-pagination>
        </div>
        <Table :width="lwidth" :loading="loading" border :columns="columns_t" :data="sortedData" :no-data-text="no_data_text" @on-selection-change="chooseProject">
        </Table>
        <div class="page-wrapper down">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="Tpage.currentPage" :page-sizes="[20,50, 100, 200, 400]" :page-size="Tpage.currentSize"
                layout="total,  prev, sizes,pager, next, jumper" :total="totalItems">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        name: "listWithPage",
        props: {
            Tpage:{
                type:Object,
                required:true,
            },
            loading:{
                type:Boolean,
                default:true,
            },
            columns_t:{
                type:Array,
                required:true,            
            },
            tivdata:{
                type:Array,
                required:true,
            },
            totalItems:{
                type:Number,
                required:true,
                default:0
            },
            no_data_text:{
                type:String,
                default:"数据加载中...",
            },
            lwidth:{
                type:Number,
                default:undefined,
            },
            selected:{
                type:Array,
                default:function(){
                    return [];
                }
            }

        },
        model:{
            prop:'selected',
            event:'on-selection-change'
        },
        computed: {
            sortedData:function(){
                let temp = this.tivdata;
                let fun = function(a,b){
                    if(a.city=="上海市"&&b.city!="上海市"){
                        return -1;
                    }else if(a.city!="上海市"&&b.city=="上海市"){
                        return 1;
                    }else if(a.city=="上海市"&&b.city=="上海市"){
                        try{
                            return parseInt(a.line_name.split("号线")[0])-parseInt(b.line_name.split("号线")[0]);
                        }catch{
                            return 0;
                        }
                    }else{
                        return 0;
                    }

                }
                temp.sort(fun);
                return temp;
            }
        },
        methods: {
            handleCurrentChange(value) {
                this.$emit("handleCurrentChange",value);
            },
            handleSizeChange(value) {
                this.$emit("handleSizeChange",value);
            },
            chooseProject(valueList){
                this.$emit('on-selection-change',valueList);
            },
        },
        data() {
            return {
                
            }
        }
    }
</script>
<style scoped>
     .page-wrapper {
        text-align: right;
        padding: 15px;
    }
    .down{
        padding-right:25px; 
    }
</style>